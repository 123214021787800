import React, { useEffect, useState } from 'react';
import './movements-wall-chart.scss';
import { MemberMovements } from "../../../../models/movements.models";
import { useSelector } from "react-redux";
import { MediumLoadingSpinner } from "../../atoms/LoadingSpinner";
import { Center } from "../../atoms/StructuralLayout";
import MovementsList from "./components/team-movements-list/MovementsList";
import { BodyRegular } from "../../atoms/fonts/Body";
import { requestLineReportsWhereabouts } from "../../../../services/Movements";
import { selectCalendarDate } from "../../../../store/ducks/dashboard.duck";
import { DATE_FORMAT } from "../../../../utils/DateUtils";
import { HeadlineSmall } from "../../atoms/fonts/Headline";
import styled from "styled-components/macro";

interface TeamWallChartProps {
  enabledSetMovements: boolean;
}

export function LineReportsWallChart(props: TeamWallChartProps) {
  const {enabledSetMovements} = props;
  const [movements, setMovements] = useState<MemberMovements[]>([]);
  const [loading, setLoading] = useState(false);
  const calendarDate = useSelector(selectCalendarDate);
  const dateString = calendarDate?.format(DATE_FORMAT);

  useEffect(() => {
    setLoading(true);
    requestLineReportsWhereabouts(dateString).then((response: MemberMovements[]) => {
      setMovements(response);
    }).catch((e: any) => {
      console.error(e);
      setMovements([]);
    }).finally(() => setLoading(false));
  }, [dateString]);

  return (
    <Container>
      {loading ?
        <>
          <Center style={{marginTop: 16}}>
            <MediumLoadingSpinner hideBorder={true} />
          </Center>
        </>
        :
        <>
          <HeadlineSmall>Your line reports</HeadlineSmall>
          {movements?.length > 0 ?
            <MovementsList movements={movements} enableSetMovements={enabledSetMovements}/> :
            <BodyRegular>No one here</BodyRegular>
          }
        </>
      }
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 24px;
`
